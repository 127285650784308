

































































import Vue from "vue";
import { MdCard, MdLayout } from "vue-material/dist/components";

Vue.use(MdCard);
Vue.use(MdLayout);

export default {
  name: "about",
  components: {},
};
